import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, TextField, CircularProgress, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import logo from '../assets/logo-v2.png';
import backgroundImg from '../assets/background.jpg';
import happyEmoji from '../assets/mutlu.png';
import cokmutluEmoji from '../assets/cokmutlu.png';
import cokmutsuzEmoji from '../assets/mutsuz.png';
import mutsuzEmoji from '../assets/mutsuz2.png';
import ortaEmoji from '../assets/orta.png';
import axios from 'axios';
import '@fontsource/roboto';
import { useSpring, animated } from '@react-spring/web';
import check from '../assets/check.png';

const questions = [
  { type: 'rating', question: 'Aldığınız hizmetten memnun kaldınız mı?' },
];

const emojiRatings = [
  { emoji: cokmutsuzEmoji, label: 'Çok Kötü' },
  { emoji: mutsuzEmoji, label: 'Kötü' },
  { emoji: ortaEmoji, label: 'Orta' },
  { emoji: happyEmoji, label: 'İyi' },
  { emoji: cokmutluEmoji, label: 'Harika' },
];

const RobotoTypography = styled(Typography)({
  fontFamily: 'Roboto, sans-serif',
  color: '#FFFFFF',
  fontSize: '16px',
});

const Home = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [formCompleted, setFormCompleted] = useState(false);
  const [answerreq,setAnswerreq]= useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:600px) and (max-width:960px)');

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex]);

  const handleRatingClick = (index) => {
    setAnswers((prev) => ({
      ...prev,
      [currentQuestionIndex]: index + 1,
    }));
    setAnswerreq(index+1)
  };

  const handleTextChange = (event) => {
    setAnswers((prev) => ({
      ...prev,
      [currentQuestionIndex]: event.target.value,
    }));
  };

  const goGog = () => {
    console.log("Yönlendirme yapılıyor...");  // Konsola log ekleyin
    window.location.href = "https://shorturl.at/LNDTE";
  }
  
  const handleNextQuestion = () => {
    console.log("Mevcut Soru İndeksi:", currentQuestionIndex); // Log ekleyin
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setLoading(true); // Yükleme animasyonunu tekrar başlat

    } else {
      setFormCompleted(true);
      console.log("Form tamamlandı.");
        
      if(answerreq > 3){
        console.log("Yönlendirme için 5 saniye bekleniyor...");
        setTimeout(() => {
          goGog();
        }, 5000);
      }else{
        try {
          const data = {
            usercode: '8503098335',
            password: '6-F96A2',
            gsmno: '5303220592',
            message: 'Acil Durum! Müşteri deneyim formundan olumsuz bir geri bildirim aldık. Hızla müdahale ederek, müşterimizi yeniden kazanabiliriz.',
            msgheader: '8503098335',
            filter: '0'
          };
    
          const result = axios.post(
            'https://api.netgsm.com.tr/sms/send/get', 
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          
          console.log(result.data);
        } catch (error) {
          console.error("There was an error sending the SMS!", error);
        }
      }
    }
  };

  const isRatingSelected = (index) => answers[currentQuestionIndex] === index + 1;
  const isAnswerProvided = answers[currentQuestionIndex] !== null && answers[currentQuestionIndex] !== '';

  // Animasyon ayarları
  const props = useSpring({
    opacity: loading || formCompleted ? 0 : 1,
    transform: loading || formCompleted ? 'translateY(20px)' : 'translateY(0px)',
    config: { tension: 220, friction: 120 },
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      p={2}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `url(${backgroundImg}) no-repeat center center fixed`,
          backgroundSize: 'cover',
          zIndex: -1
        }}
      />
      <Box mb={isSmallScreen ? 2 : 3} >
        <img src={logo} alt="Logo" style={{ maxWidth: isSmallScreen ? '220px' : isMediumScreen ? '300px' : '450px' }} />
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <CircularProgress sx={{ color: '#1E88E5' }} />
          <RobotoTypography
            mt={2}
            style={{
              fontWeight: 800,
              fontSize: '1.5rem',
              animation: 'spin 2s linear infinite'
            }}
            sx={{
              '@keyframes spin': {
                '0%': { transform: 'rotate(0deg)' },
                '100%': { transform: 'rotate(360deg)' },
              },
            }}
          >
          </RobotoTypography>
        </Box>
      ) : formCompleted ? (
<Box
  display="flex"
  flexDirection="column"
  alignItems="center"
  p={isSmallScreen ? 2 : 4}
  borderRadius={5}
  maxWidth={isSmallScreen ? '100%' : isMediumScreen ? '400px' : '700px'}
  width="100%"
  sx={{
    backgroundColor: '#1f3973',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.35)',
    transition: 'transform 0.3s ease-in-out',
    transform: 'scale(1)',
  }}
>
  {answerreq > 3 ? (
    <>
      <img src={check} style={{ maxWidth: '200px', marginBottom: '25px' }} />
      <Box sx={{width:"100%",textAlign:"center"}}>
        <CircularProgress style={{ color: '#fff', marginBottom: '15px' }} /> 
        </Box>
      <RobotoTypography
        variant="h5"
        mb={2}
        style={{
          fontWeight: 300,
          fontSize: '18px',
          fontFamily: 'Roboto',
          textAlign: 'center',
        }}
      >
        Formu Doldurduğunuz İçin Teşekkürler! <br />
        Sayfa Google İşletme sayfasına yönlendiriliyor.
      </RobotoTypography>

      <RobotoTypography
        variant="h5"
        mb={2}
        style={{
          fontWeight: 300,
          fontSize: '24px',
          fontFamily: 'Roboto',
          textAlign: 'center',
          justifyContent:"center"
        }}
      >

        
        Memnuniyetinizi Google üzerinde de dile getimeniz bizim için çok değerli.
      </RobotoTypography>
    </>
  ) : (
    <>
      <RobotoTypography
        variant="h5"
        mb={2}
        style={{ fontWeight: 300, fontSize: '18px', fontFamily: 'Roboto' }}
      >
        Formu Doldurduğunuz İçin Teşekkürler!
      </RobotoTypography>

      <CheckCircleIcon style={{ fontSize: '3rem', color: '#4CAF50' }} />
    </>
  )}
</Box>

      ) : (
        <animated.div style={props}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            p={isSmallScreen ? 2 : 4}
            borderRadius={5}
            maxWidth={isSmallScreen ? '100%' : isMediumScreen ? '400px' : '700px'}
            width="100%"
            sx={{
              backgroundColor: '#051a47',
              boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.35)',
              transition: 'transform 0.3s ease-in-out',
              transform: 'scale(1)',
            }}
          >
            <RobotoTypography
              variant="h5"
              mb={2}
              style={{ fontWeight: 300, fontSize: '18px', fontFamily: 'Roboto' }}
            >
              {questions[currentQuestionIndex].question}
            </RobotoTypography>

            {questions[currentQuestionIndex].type === 'rating' ? (
              <Box
                display="flex"
                justifyContent="space-around"
                flexWrap="wrap"
                width="100%"
                mb={3}
              >
                {emojiRatings.map((item, index) => (
                  <Box key={index} display="flex" flexDirection="column" alignItems="center" >
                    <IconButton
                      onClick={() => handleRatingClick(index)}
                      style={{
                        fontSize: isSmallScreen ? '1rem' : '1.2rem',
                        color: isRatingSelected(index) ? '#FFD700' : '#B0BEC5',
                        transition: 'color 0.3s',
                        backgroundColor: isRatingSelected(index) ? '#fff' : 'transparent',
                        borderRadius: '50%',
                        boxShadow: isRatingSelected(index) ? '0 0 4px rgba(255, 215, 0, 0.8)' : 'none',
                      }}
                    >
                      <img src={item.emoji} alt={item.label} style={{ width: '40px', height: '40px' }} />
                    </IconButton>
                    <RobotoTypography variant="body2" style={{ color: '#FFFFFF', marginTop: '4px' }}>
                      {item.label}
                    </RobotoTypography>
                  </Box>
                ))}
              </Box>
            ) : (
              <TextField
                label="Cevabınızı yazınız"
                variant="outlined"
                fullWidth
                multiline
                rows={isSmallScreen ? 2 : 3}
                value={answers[currentQuestionIndex] || ''}
                onChange={handleTextChange}
                mb={3}
                InputLabelProps={{ style: { color: '#FFFFFF' } }}
                InputProps={{ style: { color: '#FFFFFF' } }}
              />
            )}

            <Button
              variant="contained"
              onClick={handleNextQuestion}
              disabled={!isAnswerProvided}
              style={{
                fontWeight: 700,
                fontSize: '14px',
                backgroundColor: isAnswerProvided ? '#FFFFFF' : '#E0E0E0',
                color: isAnswerProvided ? '#012573' : '#9E9E9E',
                width: '100%',
                maxWidth: '180px',
                borderRadius: '15px',
                transition: 'background-color 0.3s, color 0.3s'
              }}
            >
              {currentQuestionIndex < questions.length - 1 ? 'Sonraki Soru' : 'Gönder'}
            </Button>
          </Box>
        </animated.div>
      )}
    </Box>
  );
};

export default Home;
